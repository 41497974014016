import { FormikProps } from 'formik';
import { graphql, navigate } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';
import { Checkbox } from 'ui-kit/checkbox';
import Link from 'ui-kit/link/link';

// Interfaces and Types
import { MembershipAvailablePlanCardProps } from 'display-components/membership/available-plan/card';
import MembershipAvailablePlanCardList from 'display-components/membership/available-plan/card-list';
import Stepper from 'display-components/stepper/stepper';
import TabList from 'display-components/tab-list/tab-list.component';

import { AddressVerificationAPIFailureModalContent } from 'components/add-address-form/AddAddressForm';
import { AddressVerificationForm } from 'components/address-verification-modal/AddressVerificationModal.component';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
// UI Kit & Components
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import MembershipPaymentFormPromoCode from 'components/membership-payment-form-promo-code/membership-payment-form-promo-code.component';
import MembershipPaymentForm from 'components/membership-payment-method-form/membership-payment-method-form.component';
import MembershipRegistrationShippingForm from 'components/membership-registration-shipping-form/membership-registration-shipping-form.component';
import WorkflowFooterLinks from 'components/workflow-footer-links/workflow-footer-links';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { SelectPlanState, setPayMentModel } from 'state/membership-registration/membership-registration.reducer';
import {
    membershipRegistrationGetPlanDetailsRoutine,
    membershipRegistrationPromoCodeRoutine,
    membershipRegistrationUninsuredPaymetricCredentialsRoutine,
    membershipRegistrationUninsuredRegisterRoutine,
    membershipRegistrationUninsuredTokenizedCardNumberRoutine
} from 'state/membership-registration/membership-registration.routines';
import {
    membershipRegistrationFormValuesSelector,
    membershipRegistrationPaymentModelSelector,
    membershipRegistrationSelectedPlanId,
    membershipRegistrationSelectPlanListSelector,
    membershipRegistrationValidPromoCodeSelector
} from 'state/membership-registration/membership-registration.selectors';
import { AddressParts } from 'state/usps/usps.reducers';

import { CREATE_BIRDI_ACCOUNT_SHIPPING_INFO_SCHEMA } from 'schema/create-birdi-account-shipping-information.schema';
import { formatPrice } from 'schema/price.schema';

import { MembershipPaymentMethodFormValues, MembershipRegistrationFormAddressProps } from 'types/membership';

// Utils
import { noop } from 'util/function';
import { getPhoneNumber } from 'util/globalVariables';
import { TrackError, TrackEvent } from 'util/google_optimize/optimize_helper';
import { AddressValidateResponse } from 'util/usps';

import { useAddressVerification } from 'hooks/useAddressVerification';
// Hooks
import useMembershipRegistrationInit from 'hooks/useMembershipRegistrationInit';
import usePageRedirection from 'hooks/usePageRedirection';

// Styles
import './index.scss';

const TRACKING_EVENTS = {
    SUCESSFUL_REGISTRATION: 'Successful Registration',
    FAILED_REGISTRATION: 'Failed Registration'
};

/**
 * Renders the Select Plan page.
 *
 * @param {Object} props - The props object.
 * @param {any} props.location - The location object.
 * @param {GatsbyTypes.SelectPlanPageDataQuery} props.data - The data object.
 * @return {ReactElement} The rendered Select Plan page.
 */
const SelectPlanPage = ({
    location,
    data
}: {
    location: any;
    data: GatsbyTypes.SelectPlanPageDataQuery;
}): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { verifyAddress } = useAddressVerification();
    const promoCodeRef = useRef<FormikProps<{ promoCode: string }>>(null);
    const paymentRef = useRef<FormikProps<Partial<MembershipPaymentMethodFormValues>>>(null);
    const shippingRef = useRef<FormikProps<Partial<MembershipRegistrationFormAddressProps>>>(null);

    // States
    const [availablePlans, setAvailablePlans] = useState<MembershipAvailablePlanCardProps[]>([]);
    const selectPlanValues = useSelector(membershipRegistrationSelectPlanListSelector);
    const isPromoCodeValid = useSelector(membershipRegistrationValidPromoCodeSelector);
    const paymentModel = useSelector(membershipRegistrationPaymentModelSelector);
    const formValues = useSelector(membershipRegistrationFormValuesSelector);
    const planId = useSelector(membershipRegistrationSelectedPlanId);
    const [selectedPlan, setSelectedPlan] = useState<SelectPlanState>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const [isAuthorize, setIsAuthorize] = useState<boolean>(false);
    const [showAuthorizeError, setShowAuthorizeError] = useState<boolean>(false);
    const [isTerms, setIsTerms] = useState<boolean>(false);
    const [showTermsError, setShowTermsError] = useState<boolean>(false);
    const [isShowingAlternateBilling, setShowingAlternateBilling] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [promoCode, setPromoCode] = useState<string>('');
    const [scriptUrl, setScriptUrl] = useState<string>('');
    const defaultTabOption = 'Monthly';

    // Footer links selector
    const workflowFooterLinks =
        data.allBlockContentRegistrationFlowFooterLinks?.edges?.[0]?.node?.field_registration_footer_link ?? [];

    // Hooks
    const { onInit } = useMembershipRegistrationInit();

    // membership redirection
    const hasMembershipRedirection = usePageRedirection({
        featureFlag: ENABLE_MEMBERSHIP
    });

    //call the initial routines
    useEffect(() => {
        dispatch(membershipRegistrationGetPlanDetailsRoutine.trigger());

        dispatch(
            membershipRegistrationUninsuredPaymetricCredentialsRoutine.trigger({
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onFailure: () => {},
                onSuccess: (credentials: any) => {
                    setScriptUrl(credentials.scriptUrl);
                }
            })
        );
    }, []);

    useEffect(() => {
        if (planId && selectPlanValues && !selectedPlan) {
            setSelectedPlan(selectPlanValues.filter((plan) => plan.membershipPlanId === planId)?.[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectPlanValues]);

    useEffect(() => {
        if (selectPlanValues) {
            setAvailablePlans(
                selectPlanValues.map((plan) => {
                    return {
                        membershipPlanId: plan.membershipPlanId,
                        planName: plan.membershipPlanDescription,
                        availableMembers: plan.membershipPlanAttributes[0].membershipMembers,
                        features: [
                            {
                                name:
                                    plan.membershipPlanAttributes[0].membershipIncludedrx > 1
                                        ? 'prescriptions'
                                        : 'prescription',
                                total: plan.membershipPlanAttributes[0].membershipIncludedrx,
                                monthlyFrequency: 12
                            },
                            {
                                name:
                                    plan.membershipPlanAttributes[0].membershipIncludedvisits > 1
                                        ? 'telemedicineVisits'
                                        : 'telemedicineVisit',
                                total: plan.membershipPlanAttributes[0].membershipIncludedvisits,
                                monthlyFrequency: 12
                            }
                        ],
                        ...(paymentModel === 'Yearly' && {
                            priceAccumulated: {
                                total: plan.membershipPlanAttributes[0].pricingAnnual ?? 0,
                                monthlyFrequency: 12,
                                showBilled: false
                            }
                        }),
                        joinFee: plan.membershipPlanAttributes[0].joinFeeAmount
                            ? formatPrice(plan.membershipPlanAttributes[0].joinFeeAmount)
                            : undefined,
                        price:
                            paymentModel === 'Yearly'
                                ? formatPrice(plan.membershipPlanAttributes[0].pricingAnnual / 12 || 0)
                                : formatPrice(plan.membershipPlanAttributes[0].pricingMonthly || 0),
                        isSelected: selectedPlan?.membershipPlanId === plan.membershipPlanId ?? false,
                        savePercentage: paymentModel === 'Yearly' ? 15 : null,
                        isPayAsYouGo: plan.membershipPlanAttributes[0].payAsYouGo || false,
                        onChange: () => setSelectedPlan(plan)
                    };
                })
            );
        }
    }, [selectPlanValues, selectedPlan, paymentModel]);

    useEffect(() => {
        const hasValidUserInfo = onInit();
        if (!hasValidUserInfo) {
            navigate('/get-started/membership');
        } else {
            // tracking pageview
            // @ts-expect-error Cio is a global variable
            _cio.page(window.location.href);
        }
    }, [formValues, onInit]);

    const handleTerms = (isChecked: boolean) => {
        setIsTerms(isChecked);

        if (isChecked) {
            setShowTermsError(false);
        }
    };
    const handleAuthorize = (isChecked: boolean) => {
        setIsAuthorize(isChecked);

        if (isChecked) {
            setShowAuthorizeError(false);
        }
    };

    const firstYearPayment = selectedPlan?.membershipPlanAttributes[0].pricingAnnual ?? 0;
    const firstMonthPayment = selectedPlan?.membershipPlanAttributes[0].pricingMonthly ?? 0;

    const appliedDiscount =
        paymentModel === 'Yearly'
            ? selectedPlan?.membershipPlanAttributes[0].discountAnnual ?? 0
            : selectedPlan?.membershipPlanAttributes[0].discountMonthly ?? 0;

    const totalBeforeDiscount = paymentModel === 'Yearly' ? firstYearPayment : firstMonthPayment; // disable join fee for first launch + (selectedPlan?.membershipPlanAttributes[0].joinFeeAmount ?? 0);

    const totalValue = totalBeforeDiscount - appliedDiscount;
    useEffect(() => {
        if (isPromoCodeValid === true) {
            promoCodeRef.current?.setErrors({
                promoCode: 'valid'
            });
            promoCodeRef.current?.setSubmitting(false);
        }
        if (isPromoCodeValid === false) {
            promoCodeRef.current?.setErrors({
                promoCode: 'invalid'
            });
            promoCodeRef.current?.setSubmitting(false);
        }
    }, [isPromoCodeValid]);

    useEffect(() => {
        if (selectPlanValues && selectedPlan) {
            setSelectedPlan(
                selectPlanValues?.filter((plan) => plan.membershipPlanId === selectedPlan?.membershipPlanId)[0]
            );
        }
    }, [selectPlanValues]);

    if (hasMembershipRedirection) {
        return <></>;
    }
    const submitPromCode = (values: Partial<{ promoCode: string }>) => {
        setPromoCode(values.promoCode ?? '');
        dispatch(
            membershipRegistrationPromoCodeRoutine.trigger({
                promoCode: values.promoCode,
                onFailure: () => {
                    setPromoCode('');
                    dispatch(membershipRegistrationGetPlanDetailsRoutine.trigger());
                }
            })
        );
    };

    const handleRemoveDiscount = () => {
        setPromoCode('');
        promoCodeRef.current?.resetForm();
        dispatch(membershipRegistrationGetPlanDetailsRoutine.trigger());
    };

    const handleErrorModal = () => {
        dispatch(
            openModal({
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t('registration.errors.modals.default.body')}
                        note={t('registration.errors.modals.default.note', {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })}
                    />
                ),
                ctas: [
                    {
                        dataGALocation: 'ProfileSetupError',
                        label: t('registration.errors.modals.default.button'),
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        variant: 'primary'
                    }
                ],
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('registration.errors.modals.default.title')} icon="alert" />
                ),
                showClose: true,
                type: 'danger',
                size: 'lg'
            })
        );
    };

    const handleTermsModal = () => {
        dispatch(
            openModal({
                bodyContent: (
                    <BirdiModalContent
                        title={t('membership.selectPlan.membershipTerms')}
                        body={
                            <div
                                className="text-left terms-modal-body"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(t('membership.selectPlan.membershipTermsBody'))
                                }}
                            ></div>
                        }
                    />
                ),
                className: 'birdi-terms-modal',
                ctas: [],
                showClose: true,
                size: 'lg'
            })
        );
    };

    const isAuthorizeValid = (): boolean => {
        return selectedPlan?.membershipPlanId !== 3 ? isAuthorize : true;
    };

    const isTermsValid = (): boolean => {
        return selectedPlan?.membershipPlanId !== 3 ? isTerms : true;
    };

    const verifyMemberAddress = () => {
        const address: AddressParts = {
            street1: shippingRef.current?.values.address1 || '',
            street2: shippingRef.current?.values.address2 || '',
            city: shippingRef.current?.values.city?.trim() || '',
            state: shippingRef.current?.values.state || '',
            zip: shippingRef.current?.values.zipcode || '',
            zip4: ''
        };

        const isShippingAddressValid = CREATE_BIRDI_ACCOUNT_SHIPPING_INFO_SCHEMA.isValidSync(
            shippingRef.current?.values
        );

        if (!isShippingAddressValid) {
            shippingRef.current?.validateForm();
            return;
        }

        verifyAddress({
            address,
            onSuccess: (validationResponse: AddressValidateResponse) => {
                if (validationResponse.responseCode === 'suggested') {
                    dispatch(
                        openModal({
                            showClose: false,
                            bodyContent: (
                                <AddressVerificationForm
                                    originalAddress={validationResponse.currentAddress!}
                                    correctedAddress={validationResponse.updatedAddress!}
                                    onSuccess={onVerificationAddressChoice}
                                    onClose={() => dispatch(closeModal({}))}
                                    className="membership-registration"
                                />
                            )
                        })
                    );
                }
            },
            onFailure: () => {
                dispatch(
                    openModal({
                        showClose: true,
                        className: 'prescription-modal',
                        bodyContent: <AddressVerificationAPIFailureModalContent translation={t} />,
                        ctas: [
                            {
                                label: t('modals.healthConditions.submit'),
                                variant: 'primary',
                                onClick: () => {
                                    dispatch(closeModal({}));
                                },
                                dataGALocation: 'AddressVerificationError'
                            }
                        ]
                    })
                );
            }
        });
    };

    const onVerificationAddressChoice = (address: AddressParts) => {
        dispatch(closeModal({}));
        handleJoinSubmit();
    };

    const handleJoinSubmit = () => {
        paymentRef.current?.submitForm();
        if (isShowingAlternateBilling) {
            shippingRef.current?.submitForm();
        }
        if (!isTerms) {
            setShowTermsError(true);
        }
        if (selectedPlan?.membershipPlanId !== 3) {
            if (!isAuthorize) {
                setShowAuthorizeError(true);
            }
        }
        const allFormsValues = {
            ...formValues,
            typePlan: paymentModel,
            promoCode: promoCode,
            isAuthorizePayment: selectedPlan?.membershipPlanId !== 3 ? isAuthorize : false,
            ...paymentRef.current?.values,
            membershipPlanId: selectedPlan?.membershipPlanId,
            anotherAddress: {}
        };

        const trackSelectedPlan = {
            membershipPlanId: selectedPlan?.membershipPlanId,
            planName: selectedPlan?.membershipPlanDescription,
            typePlan: paymentModel,
            isPayAsYouGo: selectedPlan?.membershipPlanAttributes[0].payAsYouGo || false
        };

        if (isShowingAlternateBilling) allFormsValues.anotherAddress = { ...shippingRef.current?.values };

        // TODO: Should we include a .catch() after this .all() and raise all errors in a single place?
        Promise.all([paymentRef.current?.submitForm()]).then((data) => {
            if (
                (paymentRef.current?.isValid && !isShowingAlternateBilling && isAuthorizeValid() && isTermsValid()) ||
                (paymentRef.current?.isValid &&
                    isShowingAlternateBilling &&
                    shippingRef.current?.isValid &&
                    isAuthorizeValid() &&
                    isTermsValid())
            ) {
                setIsSubmitting(true);

                dispatch(
                    membershipRegistrationUninsuredPaymetricCredentialsRoutine.trigger({
                        onFailure: () => {
                            setIsSubmitting(false);
                            handleErrorModal();
                            // TODO: LOG FAILED PAYMETRIC CREDENTIALS ROUTINE
                        },
                        onSuccess: (credentials: any) => {
                            if (window !== undefined && window.$XIPlugin !== undefined) {
                                const cardData = window.$XIPlugin.createJSRequestPacket(
                                    credentials?.merchantId,
                                    credentials?.accessToken
                                );
                                //Step 2 – call the Paymetric script method by sending card number.
                                cardData.addField(
                                    window.$XIPlugin.createField('SecureCardNumber', true, allFormsValues?.cardNumber)
                                );

                                window.$XIPlugin.ajax({
                                    //Step 3 – call the Paymetric POST by sending card number along with access token from step 1.
                                    url: credentials?.serviceUrl + 'Ajax',
                                    global: false,
                                    type: 'POST',
                                    data: cardData,
                                    success: function () {
                                        dispatch(
                                            membershipRegistrationUninsuredTokenizedCardNumberRoutine.trigger({
                                                formValues: { AccessToken: credentials?.accessToken },
                                                onFailure: () => {
                                                    // TODO: LOG FAILED TOKENIZED CARD ROUTINE
                                                    setIsSubmitting(false);
                                                    handleErrorModal();
                                                },
                                                onSuccess: (cardInfo: any) => {
                                                    dispatch(
                                                        membershipRegistrationUninsuredRegisterRoutine.trigger({
                                                            formValues: {
                                                                ...allFormsValues,
                                                                pciToken: cardInfo.pciToken.Body
                                                            },
                                                            onFailure: (e: { ErrorMessage: any }) => {
                                                                setIsSubmitting(false);

                                                                // track the registration error
                                                                const errorMessage =
                                                                    typeof e === 'string'
                                                                        ? e
                                                                        : e.ErrorMessage || 'Unknown Error';
                                                                TrackError(
                                                                    'membershipRegistrationUninsuredRegisterRoutine',
                                                                    TRACKING_EVENTS.FAILED_REGISTRATION,
                                                                    errorMessage
                                                                );

                                                                handleErrorModal();
                                                            },
                                                            onSuccess: () => {
                                                                // track the selected plan details but only on successful registration
                                                                TrackEvent(
                                                                    TRACKING_EVENTS.SUCESSFUL_REGISTRATION,
                                                                    JSON.stringify(trackSelectedPlan)
                                                                );
                                                                // @ts-expect-error Cio is a global variable
                                                                _cio.track('plan_purchased', {
                                                                    plan_name: selectedPlan?.membershipPlanDescription,
                                                                    billing_cycle: allFormsValues.typePlan
                                                                });

                                                                navigate('/confirm-email?flow=birdi-care');
                                                            }
                                                        })
                                                    );
                                                }
                                            })
                                        );
                                    },
                                    error: function () {
                                        // TODO: LOG FAILED PAYMETRIC REQUEST
                                        setIsSubmitting(false);
                                        handleErrorModal();
                                    }
                                });
                            }
                        }
                    })
                );
            }
        });
    };

    return (
        <WorkflowLayout
            backgroundImage={data.backgroundImage}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('membership.selectPlan.eyebrowText') }}
            anonymousOnly={true}
        >
            <WorkflowLayoutFormWrapper
                currentFlow="membership-registration"
                eyebrowText={t('membership.selectPlan.eyebrowText')}
                title={t('membership.selectPlan.profileTitle')}
            >
                <div className="membership-select-plan">
                    <div className="membership-select-plan-subtitle">
                        <p>{t('membership.selectPlan.subTitle')}</p>
                    </div>
                    <div className="membership-select-plan__sections membership-select-plan__sections__tab-list">
                        <TabList
                            tabs={['Monthly', 'Yearly']}
                            activeTab={defaultTabOption}
                            onChangeTab={(tab) => dispatch(setPayMentModel(tab))}
                        />
                        <MembershipAvailablePlanCardList planList={availablePlans} />
                    </div>
                    {selectedPlan && (
                        <>
                            <MembershipPaymentForm
                                // Formik requires the empty functions to work properly
                                onCancel={noop}
                                onSubmit={noop}
                                membershipPlanId={selectedPlan.membershipPlanId}
                                onShowingAlternateBilling={(isShowingAlternateBilling) =>
                                    setShowingAlternateBilling(isShowingAlternateBilling)
                                }
                                ref={paymentRef}
                            />
                            {isShowingAlternateBilling && (
                                <MembershipRegistrationShippingForm
                                    ref={shippingRef}
                                    onSubmit={noop}
                                    onCancel={noop}
                                    addressLabel="BillingAddress"
                                    isZipBlocked={false}
                                />
                            )}

                            {selectedPlan?.membershipPlanId !== 3 && (
                                <>
                                    <MembershipPaymentFormPromoCode
                                        onCancel={noop}
                                        onSubmit={submitPromCode}
                                        ref={promoCodeRef}
                                    />
                                    <div className="membership-select-plan__sections__total">
                                        <div className="membership-select-plan__sections__total__item">
                                            {paymentModel === 'Yearly' ? (
                                                <span>{t('membership.selectPlan.firstYear')}</span>
                                            ) : (
                                                <span>{t('membership.selectPlan.firstMonth')}</span>
                                            )}

                                            <span>
                                                <strong>{formatPrice(totalBeforeDiscount)}</strong>
                                            </span>
                                        </div>
                                        <div className="membership-select-plan__sections__total__item">
                                            <span>{t('membership.selectPlan.joinFee')}</span>
                                            <span>
                                                <strong className="join-fee-value">
                                                    {formatPrice(
                                                        selectedPlan?.membershipPlanAttributes[0].joinFeeAmount ?? 0
                                                    )}
                                                </strong>
                                            </span>
                                        </div>

                                        {promoCode && isPromoCodeValid && (
                                            <div className="membership-select-plan__sections__total__item">
                                                <span>{t('membership.selectPlan.discount', { promoCode })}</span>
                                                <span>
                                                    -<strong>{formatPrice(appliedDiscount)}</strong>
                                                    <Button
                                                        className="remove-discount"
                                                        variant="text"
                                                        type="button"
                                                        onClick={handleRemoveDiscount}
                                                        dataGAFormName="remove-discount"
                                                        label={t('membership.selectPlan.removeDiscount')}
                                                    />
                                                </span>
                                            </div>
                                        )}

                                        <div className="membership-select-plan__sections__total__item__total">
                                            <span>
                                                <strong>{t('membership.selectPlan.total')}</strong>
                                            </span>
                                            <span>
                                                {appliedDiscount > 0 && (
                                                    <s className="discount">{formatPrice(totalBeforeDiscount)}</s>
                                                )}

                                                <strong>{formatPrice(totalValue)}</strong>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="membership-select-plan__sections__authorize pt-2">
                                        <Checkbox
                                            label={
                                                <div className="membership-select-plan__sections__authorize__terms">
                                                    {t('membership.selectPlan.terms')}
                                                    {
                                                        // eslint-disable-next-line
                                                        <a
                                                            className="ml-1 text-underline"
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleTermsModal();
                                                            }}
                                                        >
                                                            {t('membership.selectPlan.birdiCareMembershipTerms')}
                                                        </a>
                                                    }
                                                    .
                                                </div>
                                            }
                                            id="terms"
                                            name="terms"
                                            onClick={(isChecked: boolean) => handleTerms(isChecked)}
                                            onCheckChanged={(isChecked: boolean) => handleTerms(isChecked)}
                                            hasError={!!showTermsError}
                                        />
                                        {showTermsError && (
                                            <p className="membership-select-plan__sections__authorize__error">
                                                {t('membership.selectPlan.termsError')}
                                            </p>
                                        )}
                                    </div>
                                    <div className="membership-select-plan__sections__authorize">
                                        <Checkbox
                                            label={
                                                <>
                                                    <div
                                                        className="mb-2"
                                                        dangerouslySetInnerHTML={{
                                                            __html: sanitizeHtml(
                                                                t('membership.selectPlan.authorize', {
                                                                    phoneNumber: getPhoneNumber({ isEnd: true })
                                                                })
                                                            )
                                                        }}
                                                    ></div>
                                                </>
                                            }
                                            id="authorize"
                                            name="authorize"
                                            onClick={(isChecked: boolean) => handleAuthorize(isChecked)}
                                            onCheckChanged={(isChecked: boolean) => handleAuthorize(isChecked)}
                                            hasError={!!showAuthorizeError}
                                        />
                                        {showAuthorizeError && (
                                            <p className="membership-select-plan__sections__authorize__error">
                                                {t('membership.selectPlan.authorizeError')}
                                            </p>
                                        )}
                                    </div>
                                </>
                            )}

                            <div className="membership-select-plan__sections membership-select-plan__sections__cta">
                                <Button
                                    async
                                    label={t('membership.selectPlan.ctas.continue')}
                                    onClick={() =>
                                        isShowingAlternateBilling ? verifyMemberAddress() : handleJoinSubmit()
                                    }
                                    type="submit"
                                    variant="primary"
                                    disabled={isSubmitting}
                                    isBusy={isSubmitting}
                                    dataGALocation={'membership'}
                                    dataGAFormStep={'Step4'}
                                    dataGAFormStepName={'Select Plan'}
                                />
                                <Link
                                    to={'/get-started/personal-details'}
                                    label={t('membership.selectPlan.ctas.backLink')}
                                    dataGALocation={'membership'}
                                    variant="subtle"
                                    external={false}
                                />
                            </div>

                            <div className="membership-select-plan__sections__agreement">
                                <p>{t('membership.selectPlan.userAgreement')}</p>
                                <p>{t('membership.selectPlan.userAgreement1')}</p>
                                <p>{t('membership.selectPlan.userAgreement3')}</p>
                            </div>
                        </>
                    )}
                </div>
                <Stepper activeStep={3} steps={['1', '2', '3', '4']} />
            </WorkflowLayoutFormWrapper>

            {/* Workflow links */}
            <WorkflowFooterLinks links={workflowFooterLinks as any} locationGAflow="membership personal details" />

            {scriptUrl ? (
                <Helmet>
                    <script id="service-url" src={scriptUrl} />
                </Helmet>
            ) : (
                <></>
            )}
        </WorkflowLayout>
    );
};

export default SelectPlanPage;

export const query = graphql`
    query SelectPlanPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        allBlockContentRegistrationFlowFooterLinks(
            filter: { field_registration_flow_types: { eq: "membership_flow" } }
        ) {
            edges {
                node {
                    field_registration_footer_link {
                        title
                        uri
                    }
                }
            }
        }
    }
`;
